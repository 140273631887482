define("discourse/plugins/twitter-profile-link/discourse/templates/connectors/user-profile-primary/twitter-profile", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if model.twitter_screen_name}}
    <div class="twitter-profile">
      <a href="https://twitter.com/{{model.twitter_screen_name}}">
        {{fa-icon "twitter"}}&nbsp;<span>Twitter profile</span>
      </a>
    </div>
  {{/if}}
  */
  {
    "id": "uTkEaGSK",
    "block": "[[[41,[30,0,[\"model\",\"twitter_screen_name\"]],[[[1,\"  \"],[10,0],[14,0,\"twitter-profile\"],[12],[1,\"\\n    \"],[44,[[28,[37,2],null,[[\"model\"],[[28,[32,0],[\"model\"],null]]]]],[[[10,3],[15,6,[29,[\"https://twitter.com/\",[52,[30,1,[\"model\"]],[28,[30,1,[\"model\"]],null,null],[28,[32,1],[[30,0],\"model.twitter_screen_name\",\"[\\\"The `model` property path was used in the `discourse/plugins/twitter-profile-link/discourse/templates/connectors/user-profile-primary/twitter-profile.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"twitter\"],null]],[1,\" \"],[10,1],[12],[1,\"Twitter profile\"],[13],[1,\"\\n    \"],[13]],[1]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,2],[\"[[\\\"The `model` property path was used in the `discourse/plugins/twitter-profile-link/discourse/templates/connectors/user-profile-primary/twitter-profile.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"if\",\"let\",\"hash\",\"fa-icon\"]]",
    "moduleName": "discourse/plugins/twitter-profile-link/discourse/templates/connectors/user-profile-primary/twitter-profile.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});